
  import { defineComponent, reactive, toRefs, computed, onBeforeMount } from 'vue';
  import { useToast } from "vue-toastification";

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTable from '@/components/stateless/AppTable.vue';

  import { routesNames, router } from '@/router';
  import { vuex } from '@/store';
  import { adminUsersService } from '@/services';
  import { IAdminUserForm, IAdminsRes, IAdminsData } from '@/types';

  export default defineComponent({
    name: 'Admins',

    components: { AppButton, AppIcon, AppTable },

    setup() {
      const toast = useToast();
      const state = reactive({
        usersList: [] as Array<IAdminUserForm>,
      });

      const tableHeaders = computed(() => [
        { property: 'firstName', label: 'First Name', minWidth: 160, sortable: true },
        { property: 'lastName', label: 'Last Name', minWidth: 180, sortable: true },
        { property: 'email', label: 'Email', minWidth: 280, sortable: true }
      ]);

      const { usersList } = toRefs(state);

      function mapAdmins(admins: IAdminsRes) {

        return admins.data.map(
          (el: IAdminsData) => {
            const {
              email,
              firstName,
              lastName,
            } = el.details.attributes;
            return ({
              id: el.userIdentityId,
              email,
              firstName,
              lastName
            });
          }
        );
      }

      async function fetchUsers() {
        vuex.setLoading(true);

        return adminUsersService.fetchAdmins()
          .then((res: IAdminsRes) => {
            usersList.value = mapAdmins(res); 
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount( async() => {
        await fetchUsers();
      });

      return {
        routesNames,
        router,
        usersList,
        vuex,
        tableHeaders
      };
    }

  });
