<template>
  <div class="bg-white rounded-5 px-50 py-35">
    <div class="mb-25">
      <div
        class="inline-flex items-center text-grey-fp-50 cursor-pointer"
        @click="router.push({ name: routesNames.adminSettings })"
      >
        <AppIcon
          name="arrow-thin-left"
          class="mr-10"
        />
        <p class="text-md">Back to Settings</p>
      </div>
    </div>

    <div class="flex items-center justify-between mb-35">
      <div class="flex items-center">
        <AppIcon
          name="users"
          size="30"
          class="text-blue-ap-100 mr-20"
        />
        <p class="text-2xl">Admins</p>
      </div>

      <AppButton
        type="primary"
        class="px-30"
        icon-size="16"
        icon="plus"
        size="small"
        @click="router.push({ name: routesNames.adminAddAdmin })"
      >
        Add New Admin
      </AppButton>
    </div>

    <AppTable
      :dataset="usersList"
      :headers="tableHeaders"
      :loading="vuex.isLoading"
      class="users-table"
      rowHeight="50px"
      type="admin"
      theme="primary"
      show-actions
      sort-front-side
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs, computed, onBeforeMount } from 'vue';
  import { useToast } from "vue-toastification";

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTable from '@/components/stateless/AppTable.vue';

  import { routesNames, router } from '@/router';
  import { vuex } from '@/store';
  import { adminUsersService } from '@/services';
  import { IAdminUserForm, IAdminsRes, IAdminsData } from '@/types';

  export default defineComponent({
    name: 'Admins',

    components: { AppButton, AppIcon, AppTable },

    setup() {
      const toast = useToast();
      const state = reactive({
        usersList: [] as Array<IAdminUserForm>,
      });

      const tableHeaders = computed(() => [
        { property: 'firstName', label: 'First Name', minWidth: 160, sortable: true },
        { property: 'lastName', label: 'Last Name', minWidth: 180, sortable: true },
        { property: 'email', label: 'Email', minWidth: 280, sortable: true }
      ]);

      const { usersList } = toRefs(state);

      function mapAdmins(admins: IAdminsRes) {

        return admins.data.map(
          (el: IAdminsData) => {
            const {
              email,
              firstName,
              lastName,
            } = el.details.attributes;
            return ({
              id: el.userIdentityId,
              email,
              firstName,
              lastName
            });
          }
        );
      }

      async function fetchUsers() {
        vuex.setLoading(true);

        return adminUsersService.fetchAdmins()
          .then((res: IAdminsRes) => {
            usersList.value = mapAdmins(res); 
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount( async() => {
        await fetchUsers();
      });

      return {
        routesNames,
        router,
        usersList,
        vuex,
        tableHeaders
      };
    }

  });
</script>

<style lang="scss" scoped>
  .users-table {
    max-height: 455px;
  }
</style>